import styled from 'styled-components';
import { CaretRight } from 'phosphor-react';

import { getDimension } from 'styled/helpers/getDimension';

import type { HTMLAttributes, FC } from 'react';

interface Props extends HTMLAttributes<HTMLElement> {
  withStructuredData: boolean;
}

const StyledQuestion = styled.summary.attrs((props: Props) => {
  // WARNING: These should be capitalized, eg. `itemProp`, so they are passed down to the DOM.
  if (props.withStructuredData) return { itemProp: 'name' };

  return undefined;
})<Props>`
  cursor: pointer;
  padding: ${getDimension('size2')};
  padding-left: 0;
  font-weight: bold;

  /** Get rid of the default "details/summary" icon and align it */
  display: flex;
  list-style: none;

  /** Further disable the disclosure widget. */
  counter-increment: none;
  align-items: center;
  &::-webkit-details-marker {
    display: none;
  }

  details & svg {
    transition: transform ease-in-out 0.2s;
    flex-shrink: 0;
  }

  details[open] & svg {
    transform: rotate(90deg);
    color: ${props => props.theme.colors.primary};
  }
`;

export const Question: FC<Props> = ({ children, withStructuredData, ...props }) => (
  <StyledQuestion {...props} withStructuredData={withStructuredData}>
    <CaretRight weight="bold" fontSize="2.4rem" />
    {children}
  </StyledQuestion>
);
