import { ContentStyles } from './ContentStyles.styled';

import type { FC } from 'react';

interface StyledHtmlProps {
  html: string;
}

export const StyledHtml: FC<StyledHtmlProps> = ({ html }) => (
  <ContentStyles>
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  </ContentStyles>
);
