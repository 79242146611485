import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

import type { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  withStructuredData: boolean;
}

export const Detail = styled.details.attrs((props: Props) => {
  if (props.withStructuredData) {
    // WARNING: These should be capitalized, eg. `itemProp`, so they are passed down to the DOM.
    return {
      itemScope: true,
      itemProp: 'mainEntity',
      itemType: 'https://schema.org/Question',
    };
  }

  return undefined;
})<Props>`
  & + & {
    margin-top: ${getDimension('size2')};
  }
`;
